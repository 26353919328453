import ReactGA from 'react-ga4';
import LogRocket from 'logrocket';
import useApi from './useApi';
import { isIOS13 } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import { clarity } from 'react-microsoft-clarity';
import { ELocalStorageKeys } from '../constants/enums';
import { localStorageUtil } from '../utils';
import { useState } from 'react';

const CLARITY_ID = 'ji7njsuutu';

export default function useAnalytics() {
    const API = useApi({});
    const [initionlized, setInitionlized] = useState(false);
    const [mixInitionlized, setMixInitionlized] = useState(false);
    const mixPanelId = '36bc8102bd15fc0a7471651e632a1c1d';

    const init = () => {
        const publisherMetaData = API.getPublisherMeta.data;
        if (!mixInitionlized) {
            setMixInitionlized(true);
            mixpanel.init(mixPanelId, { debug: false });
        }
        if (!localStorageUtil.get(ELocalStorageKeys.ANALYTICS) || initionlized)
            return;
        clarity.init(CLARITY_ID);
        clarity.consent();
        setInitionlized(true);
        if (publisherMetaData!.externalServicesConfig.gaActive) {
            ReactGA.initialize(
                publisherMetaData!.integration.googleAnalyticsMeasurementId,
                {
                    testMode:
                        !process.env.NODE_ENV ||
                        process.env.NODE_ENV === 'development'
                }
            );
            ReactGA.ga((tracker: any) => {
                localStorage.setItem('ga_client_id', tracker.get('clientId'));
            });
        }
        if (publisherMetaData!.externalServicesConfig.mixpanelActive) {
            // mixpanel.init(mixPanelId, {debug: false});
        }
        if (
            publisherMetaData!.externalServicesConfig.logRocketActive &&
            isIOS13
        ) {
            LogRocket.init(
                `${publisherMetaData!.integration.logRocketAppId}/main-ijpwr`
            );
            LogRocket.identify(mixpanel.get_distinct_id());
        }
    };
    return { init };
}
