import { Stack } from '@mui/material';
import './style.scss';
import { useEffect, useState } from 'react';
import { localStorageUtil } from '../../utils';
import { ELocalStorageKeys } from '../../constants/enums';
import { useTranslation } from 'react-i18next';

const DevDialog = ({ onClose }: { onClose: () => void }) => {
    const [offersApiOverride, setOffersApiOverride] = useState(
        localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE)
    );
    const [bootApiOverride, setBootApiOverride] = useState(
        localStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE)
    );
    const [postLoginApiOverride, setPostLoginApiOverride] = useState(
        localStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE)
    );
    const [overrideStore, setOverrideStore] = useState(
        localStorageUtil.get(ELocalStorageKeys.OVERRIDE_STORE)
    );

    const [overrideInput, setOverrideInput] = useState('');

    const [selectedOption, setSelectedOption] = useState('appcharge');

    useEffect(() => {
        const domain =
            selectedOption === 'appcharge'
                ? '.appcharge.com'
                : '.appchargestore.com';
        const url = overrideInput + domain;
        setOverrideStore(!overrideInput.trim() ? '' : url);
    }, [overrideInput, selectedOption]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSelectedOption(event.target.value);

    const { t } = useTranslation();

    const onSave = () => {
        offersApiOverride &&
            localStorageUtil.set(
                ELocalStorageKeys.OFFER_API_OVERRIDE,
                JSON.stringify(JSON.parse(offersApiOverride))
            );
        bootApiOverride &&
            localStorageUtil.set(
                ELocalStorageKeys.BOOT_API_OVERRIDE,
                JSON.stringify(JSON.parse(bootApiOverride))
            );
        postLoginApiOverride &&
            localStorageUtil.set(
                ELocalStorageKeys.POST_LOGIN_API_OVERRIDE,
                JSON.stringify(JSON.parse(postLoginApiOverride))
            );
        overrideStore &&
            localStorageUtil.set(
                ELocalStorageKeys.OVERRIDE_STORE,
                overrideStore
            );
        window.location.reload();
    };

    const onClear = () => {
        const p = window.prompt('Are you sure?');
        if (p) {
            localStorageUtil.remove(ELocalStorageKeys.OFFER_API_OVERRIDE);
            localStorageUtil.remove(ELocalStorageKeys.BOOT_API_OVERRIDE);
            localStorageUtil.remove(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE);
            localStorageUtil.remove(ELocalStorageKeys.OVERRIDE_STORE);
            window.location.reload();
        }
    };

    return (
        <dialog aria-hidden="true" open>
            <code>🤖 Dev Panel 🤖</code>
            <Stack mt={2} textAlign="left">
                <strong>Override Store</strong>
                <Stack direction="row">
                    <input
                        value={overrideInput}
                        onChange={(e) => setOverrideInput(e.target.value)}
                    />
                    <input
                        type="radio"
                        value="appcharge"
                        checked={selectedOption === 'appcharge'}
                        onChange={handleCheckboxChange}
                    />{' '}
                    .appcharge.com
                    <input
                        type="radio"
                        value="appchargestore"
                        checked={selectedOption === 'appchargestore'}
                        onChange={handleCheckboxChange}
                    />{' '}
                    .appchargestore.com
                </Stack>
            </Stack>
            <Stack mt={2} textAlign="left">
                <strong>{t('devDialog.overrideGetOfferApi')}</strong>
                <textarea
                    placeholder={t('devDialog.jsonGoesHere')}
                    rows={5}
                    onChange={(e) => setOffersApiOverride(e.target.value)}
                >
                    {offersApiOverride}
                </textarea>
            </Stack>
            <Stack mt={2} textAlign="left">
                <strong>Override GET Boot API</strong>
                <textarea
                    placeholder={t('devDialog.jsonGoesHere')}
                    rows={5}
                    onChange={(e) => setBootApiOverride(e.target.value)}
                >
                    {bootApiOverride}
                </textarea>
            </Stack>
            <Stack mt={2} textAlign="left">
                <strong>{t('devDialog.overrideGetOfferApi')}</strong>
                <textarea
                    placeholder={t('devDialog.jsonGoesHere')}
                    rows={5}
                    onChange={(e) => setPostLoginApiOverride(e.target.value)}
                >
                    {postLoginApiOverride}
                </textarea>
            </Stack>
            <Stack
                mt={2}
                direction="row"
                justifyContent="center"
                gap={2}
                width="100%"
            >
                <button
                    style={{ cursor: 'pointer', fontSize: '2em' }}
                    onClick={() => onSave()}
                >
                    {t('devDialog.save')} 👌
                </button>
                <button
                    style={{ cursor: 'pointer', fontSize: '2em', opacity: 0.5 }}
                    onClick={() => onClear()}
                >
                    {t('devDialog.clear')} 🗑️
                </button>
            </Stack>
            <Stack mt={2} alignItems="center" gap={2}>
                <button type="button" onClick={() => onClose()}>
                    {t('devDialog.close')}
                </button>
            </Stack>
        </dialog>
    );
};

export default DevDialog;
