import { FacebookPictureResponse } from '../constants/apiResponses.types';

declare global {
    interface Window {
        FB: any;
    }
}

export default function useFacebook(appId: string) {
    window?.FB?.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v11.0'
    });

    const getFacebookProfile = (token: string): Promise<string> => {
        const apiCallPromise = new Promise<string>((resolve, reject) => {
            window?.FB?.api(
                '/me',
                { fields: 'picture', access_token: token },
                (response: FacebookPictureResponse) => {
                    if (response?.picture?.data?.url) {
                        resolve(response?.picture?.data?.url);
                    } else {
                        reject(new Error('Profile picture URL not found'));
                    }
                }
            );
        });

        const timeoutPromise = new Promise<string>((_, reject) => {
            setTimeout(() => {
                reject(new Error('Request timed out after 5 seconds'));
            }, 5000);
        });

        return Promise.race([apiCallPromise, timeoutPromise]);
    };

    return { getFacebookProfile };
}
