import { Background } from '@appcharge/shared-ui';
import { MessagePageProps } from './message-page.types';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import './style.scss';

const MessagePage = ({ images }: MessagePageProps) => {
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    return (
        <Background
            backgroundImageMobile={
                publisherMetaData.storeTheme.general.backgroundImageMobile
            }
            backgroundImageDesktop={
                publisherMetaData.storeTheme.general.backgroundImageDesktop
            }
        >
            <div
                id="static"
                style={{
                    fontFamily: publisherMetaData.storeTheme.general.font
                }}
            >
                {images?.map((img, i) => (
                    <img
                        key={i}
                        alt={img.class}
                        className={img.class}
                        src={img.src}
                    />
                ))}
            </div>
        </Background>
    );
};

export default MessagePage;
