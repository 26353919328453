import mixpanel from 'mixpanel-browser';
import { localStorageUtil } from '../utils';
import { ELocalStorageKeys, ESessionStorageKeys, EStorePhase } from '../constants/enums';

export default function useCustomEvents() {
    const publisherMetaData = localStorageUtil.get(
        ELocalStorageKeys.PUBLISHER_META
    );

    const sendCustomEvent = (
        eventType: string,
        options: any,
        eventPhase: EStorePhase = EStorePhase.OTHER
    ) => {
        // if (!localStorageUtil.get(ELocalStorageKeys.ANALYTICS)) return;
        const playerData = localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)
        options.environment = process.env.REACT_APP_ENV || 'local';
        options.publisher_id = publisherMetaData.storeTheme.publisherId;
        options.URL = window.location.origin;
        if (eventPhase === EStorePhase.POST_LOGIN) {
            if (playerData) {
                options.player_id = playerData.playerId;
                options.player_country = playerData.playerCountry;
            }
            const sessionData = localStorageUtil.get(
                ESessionStorageKeys.SESSION_DATA, true
            );
            if (sessionData?.id) {
                options.session_id = sessionData.id
            }
        }
        if (publisherMetaData.externalServicesConfig.mixpanelActive) {
            mixpanel.track(eventType, options);
        }
    };

    const setDistinctId = () => {
        const playerData = localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)
        mixpanel.identify(`${publisherMetaData?.storeTheme?.publisherId}_${playerData?.playerId}`);
    };

    const resetSession = () => {
        mixpanel.reset();
    };

    return {
        sendCustomEvent,
        setDistinctId,
        resetSession
    };
}
