import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import { SpecialOfferProps } from './SpecialOIffer.types';
import {
    BagelCodeSpecialOffer,
    LocoBingoSpecialOffer,
    Me2onSpecialOffer,
    MatchMastersSpecialOffer,
    PokerFaceSpecialOffer,
    SpecialOffer as HugeSpecialOffer
} from '@appcharge/shared-ui';
import {
    EBundlesInternalViewModel,
    ESalePercentageDisplayType,
    ESpecialOfferInternalViewModel
} from '../../constants/enums';
import { t } from 'i18next';
import { useRef } from 'react';

const bundleDesignDic = {
    [ESpecialOfferInternalViewModel.HUGE]: HugeSpecialOffer,
    [ESpecialOfferInternalViewModel.MATCH_MASTERS]: MatchMastersSpecialOffer,
    [ESpecialOfferInternalViewModel.POKER_FACE]: PokerFaceSpecialOffer,
    [ESpecialOfferInternalViewModel.BAGELCODE]: BagelCodeSpecialOffer,
    [ESpecialOfferInternalViewModel.LOCO_BINGO]: LocoBingoSpecialOffer,
    [ESpecialOfferInternalViewModel.ME2ON]: Me2onSpecialOffer
};

const SpecialOffer = ({ data, selectOffer }: SpecialOfferProps) => {
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const borderColor =
        publisherMetaData.storeTheme.storeScreen.bundleBorderColor;
    const SpecialOfferDesign =
        bundleDesignDic[
            publisherMetaData?.storeTheme.general.specialOffersInternalViewModel
        ];
    const specialOfferRef = useRef<HTMLDivElement>(null);

    return (
        <SpecialOfferDesign
            availability={data.playerAvailability}
            playerAvailability={data.offerPlayerRemainingAvailability}
            text={data.offerUi.specialOffer!.title}
            backgroundColor={data.offerUi.specialOffer!.backgroundColor}
            textColor={data.offerUi.specialOffer!.fontColor}
            fontSize={data.offerUi.specialOffer!.fontSize}
            fontWeight={data.offerUi.specialOffer!.fontWeight}
            borderColor={borderColor}
            buttonColor={publisherMetaData.storeTheme.general.buttonColor}
            backgroundImage={data.offerUi.backgroundImage}
            design={
                publisherMetaData.storeTheme.general
                    .bundlesInternalViewModel ===
                EBundlesInternalViewModel.MATCH
                    ? EBundlesInternalViewModel.MATCH
                    : publisherMetaData.storeTheme.general
                          .specialOffersInternalViewModel
            }
            price={data.productsSequence[0].price}
            endTime={new Date(data.endTime).getTime()}
            endTimeActive={data.offerUi.specialOffer!.presentOfferEndTimer}
            productsList={data.productsSequence[0].products}
            selectOffer={() => selectOffer()}
            buttonTextColor="white"
            salePercentage={data.dynamicOfferUi?.salePercentage}
            salePercentageDisplayType={
                data.dynamicOfferUi?.salePercentageDisplayType ||
                ('percentage' as ESalePercentageDisplayType)
            }
            isFree={data.productsSequence[0].price.price === 0}
            availabilityText={t('availability')}
            collectText={t('collect')}
            specialOfferRef={specialOfferRef}
        />
    );
};

export default SpecialOffer;
