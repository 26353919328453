import styled from 'styled-components';

export const StyledTextContainer = styled.div`
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  .checkout-completion__text-container {
    &__header {
      margin: unset;
      color: ${(props: any) => props.headerColor};
      font-size: ${(props: any) => props.headerSize + 'px'};
      font-weight: ${(props: any) => props.headerWeight};
    }
    
    &__sub-header {
      color: ${(props: any) => props.textColor};
      font-size: ${(props: any) => props.textSize + 'px'};
      font-weight: ${(props: any) => props.textWeight};
    }
  }
` as any;