import { useEffect } from 'react';
import useCustomEvents from './useCustomEvents';
import { EEventsType, EStorePhase } from '../constants/enums';
import { OfferData } from '../constants/apiResponses.types';
import { getBadgeName } from '../utils';

const observedItems = [] as string[];

export default function useObserver(element: any, data: OfferData) {
    const customEvents = useCustomEvents();

    useEffect(() => {
        if (!element) return;
        const options = {
            threshold: 1.0
        };
        const observer = new IntersectionObserver((entry, observer) => {
            if (!entry[0].isIntersecting) return;
            if (!observedItems.includes(data.offerId)) {
                observedItems.push(data.offerId);
                customEvents.sendCustomEvent(
                    EEventsType.OFFERS_SHOWN,
                    {
                        offer_id: data.offerId,
                        badge_left: getBadgeName( 'left', data.dynamicOfferUi?.badges, ),
                        badge_right: getBadgeName( 'right', data.dynamicOfferUi?.badges),
                        badge_center: getBadgeName( 'center', data.dynamicOfferUi?.badges),
                        sale_percentage: data.dynamicOfferUi?.salePercentage,
                        type: data.offerType,
                        sub_type: data?.subType
                    },
                    EStorePhase.POST_LOGIN
                );
            }
            observer.disconnect();
        }, options);
        observer.observe(element);
    }, [element]);
}
