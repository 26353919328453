import { OrderSetProduct, OrderSetProps } from './order-set.types';
import Typography from '@mui/material/Typography';
import './style.scss';

const OrderSet = ({ products, showBackground }: OrderSetProps) => {
    return (
        <div id="orderSet" className="fadein">
            <div
                className={
                    'products' + (showBackground ? ' products--background' : '')
                }
            >
                {products.map((product: OrderSetProduct, i: number) => {
                    return (
                        <div className="product" key={i}>
                            <img
                                alt="product image"
                                src={product.image}
                                data-testid="storeSuccessProductImage"
                            />
                            <Typography
                                data-testid="storeSuccessProductAmount"
                                variant="body2"
                                component="span"
                                style={{ color: 'white' }}
                            >
                                {product.quantityDisplay}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OrderSet;
