import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { CookieConcentProps } from './cookie-consent.types';
import './style.scss';
import { APPCHARGE_PRIVACY_POLICY_URL } from '../../constants/defaults';

const CookieConsent = ({ setShowCookieOverlay }: CookieConcentProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [enableAnalytics, setEnableAnalytics] = useState(true);

    const { t } = useTranslation();

    const openDialog = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setShowDialog(true);
    };

    const saveSettings = () => {
        localStorage.setItem('analytics', JSON.stringify(enableAnalytics));
        setShowCookieOverlay(false);
    };

    const closeDialog = () => {
        setShowDialog(false);
    };

    const toggleAnalytics = () => {
        setEnableAnalytics(!enableAnalytics);
    };

    return (
        <div id="cookieConesnt" onClick={closeDialog}>
            {showDialog && (
                <div
                    className="dialog"
                    onClick={(event) => event.stopPropagation()}
                >
                    <Typography variant="h3">
                        {t('cookieConsent.cookiesSettings')}
                    </Typography>
                    <div>
                        <div className="header">
                            <Typography variant="h4">
                                {t(
                                    'cookieConsent.strictlyNecessaryCookies.title'
                                )}
                            </Typography>
                            <label className="switch disabled">
                                <input type="checkbox" checked />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <Typography variant="body1">
                            {t(
                                'cookieConsent.strictlyNecessaryCookies.description'
                            )}
                        </Typography>
                    </div>
                    <div>
                        <div className="header">
                            <Typography variant="h4">
                                {t('cookieConsent.analyticsCookies.title')}
                            </Typography>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={enableAnalytics}
                                    onChange={toggleAnalytics}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <Typography variant="body1">
                            {t('cookieConsent.analyticsCookies.description')}
                        </Typography>
                    </div>
                    <button onClick={saveSettings}>
                        {t('cookieConsent.saveSettings')}
                    </button>
                </div>
            )}
            <div className="banner">
                <Typography variant="subtitle1" component="p" align="center">
                    <Trans i18nKey="cookieConsent.overallCookieConsentDescription">
                        We use cookies to save information on your device. Some
                        help make this site work, others to enhance your
                        experience! You can accept all cookies or visit cookie
                        settings to adjust your preference. See our
                        <a
                            target="_blank"
                            href={APPCHARGE_PRIVACY_POLICY_URL}
                            rel="noreferrer"
                        >
                            privacy policy
                        </a>{' '}
                        for more.
                    </Trans>
                </Typography>
                <div>
                    <button className="adjust" onClick={openDialog}>
                        {t('cookieConsent.adjustCookies')}
                    </button>
                    <button className="accept" onClick={saveSettings}>
                        {t('cookieConsent.acceptAll')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
