import { Box, Button, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { ActionButton, Background, MessagePage } from '@appcharge/shared-ui';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
    ECheckoutPageEvent,
    EerrorCodes,
    EEventsType,
    ELocalStorageKeys,
    EQueryParams,
    EResultOptions,
    EStorePhase,
    ESupportType
} from '../../constants/enums';
import useApi from '../../hooks/useApi';
import {
    getPlatformData,
    localStorageUtil,
    sendMessageToIframeParent
} from '../../utils';
import { BootResponse } from '../../constants/apiResponses.types';
import useUrlQuery from '../../hooks/useUrlQuery';
import { EOrderStatus } from '../checkout/checkout.types';
import { EFontWeights } from '@appcharge/shared-ui/lib/components/Helper/enums';
import { getReturnToGameLinkAddress } from '../../components/BackToGameButton/BackToGameButton.utils';
import BackToGameButton from '../../components/BackToGameButton/BackToGameButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTextContainer } from '../checkout/style';
import './style.scss';

const Failed = () => {
    const API = useApi({});
    const { t } = useTranslation();
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const theme = publisherMetaData?.storeTheme?.completedScreen as any;
    const customEvents = useCustomEvents();
    const isIframe = (useUrlQuery(EQueryParams.IS_IFRAME) as string) === 'true';
    const msg = useUrlQuery(EQueryParams.MESSAGE) as string;
    const orderId = useUrlQuery(EQueryParams.ORDER_ID) as string;
    const currencyCode = useUrlQuery(EQueryParams.CURRENCY_CODE) as string;
    const error = useUrlQuery(EQueryParams.ERROR) as EerrorCodes | EOrderStatus;
    const navigate = useNavigate();
    const isUserLoggedIn =
        localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) !== null;
    const [returnToGameLinkAddress, setReturnToGameLinkAddress] =
        useState<string>();
    const isNewCheckoutEnabled = Boolean(
        publisherMetaData.featureFlags?.store_independent_checkout_flow
    );

    useEffect(() => {
        const interval = setInterval(() => {
            window.parent.postMessage('iframe-loaded', '*');
        }, 500);

        const handleMessage = (event: { data: string }) => {
            if (event.data === 'stop-messages') {
                clearInterval(interval);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            clearInterval(interval);
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const dic: Record<string, Record<string | EOrderStatus, string>> = {
        headline: {
            default: t('failed.somethingWentWrong'),
            [EOrderStatus.CHARGE_FAILED]: t('failed.somethingWentWrong'),
            [EOrderStatus.PAYMENT_FAILED]: t('failed.ohNo'),
            auth: t('failed.whoops')
        },
        lines: {
            default: t('failed.somethingWentWrong'),
            [EOrderStatus.CHARGE_FAILED]: t('failed.paymentSuccessful'),
            [EOrderStatus.PAYMENT_FAILED]: t('failed.paymentFailed'),
            auth: t('failed.unableToAccessStore')
        }
    };

    const returnToShop = () => {
        if (isIframe) {
            sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_STORE, {});
        } else {
            navigate('../login');
        }
    };

    const openSupport = () => {
        customEvents.sendCustomEvent(
            EEventsType.SUPPORT_FORM_OPEN,
            {
                phase: EStorePhase.PRE_LOGIN,
                supportModel:
                    publisherMetaData.supportConfiguration?.supportModel
            },
            EStorePhase.PRE_LOGIN
        );
        if (publisherMetaData.supportConfiguration?.externalSupportUrl) {
            API.sendEmptySupport(
                publisherMetaData.storeTheme.publisherId,
                ESupportType.PRE_LOGIN,
                EStorePhase.PRE_LOGIN
            );
            window.location.href =
                publisherMetaData.supportConfiguration.externalSupportUrl;
        } else {
            if (isIframe) {
                sendMessageToIframeParent(ECheckoutPageEvent.SUPPORT, {
                    supportUrl: `/support/${ESupportType.POST_LOGIN}/${EStorePhase.POST_ORDER}`
                });
            } else {
                navigate(
                    `/support/${ESupportType.PRE_LOGIN}/${EStorePhase.PRE_LOGIN}`
                );
            }
        }
    };

    useEffect(() => {
        if (error === EerrorCodes.AUTH || error === EerrorCodes.PAYMENT) return;
        customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
                msg: msg
            },
            isUserLoggedIn ? EStorePhase.POST_LOGIN : EStorePhase.PRE_LOGIN
        );
        if (
            (error === EOrderStatus.PAYMENT_FAILED ||
                error === EOrderStatus.CHARGE_FAILED) &&
            isNewCheckoutEnabled
        ) {
            customEvents.sendCustomEvent(
                EEventsType.COMPLETE_SCREEN_PRESENTED,
                {
                    status: EResultOptions.FAILED,
                    currency_code: currencyCode,
                    platform: getPlatformData(),
                    order_id: orderId
                },
                EStorePhase.POST_LOGIN
            );
        }
    }, [error]);

    useEffect(() => {
        const deepLinks = publisherMetaData?.integration.deepLinks;
        if (deepLinks.length > 0) {
            const backToGameLink = getReturnToGameLinkAddress(deepLinks);
            setReturnToGameLinkAddress(backToGameLink);
        }
    }, [publisherMetaData]);

    const isBackToGameBtnShown =
        publisherMetaData?.integration.backToGameButtonOn &&
        publisherMetaData.storeTheme.completedScreen.backToGameButtonText &&
        returnToGameLinkAddress &&
        (isIOS || isAndroid || !isMobile);

    const backToStore = () => {
        if (isIframe) {
            sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_STORE, {});
        } else {
            navigate('../shop');
        }
    };

    return (
        <>
            {isIframe ? (
                <Box className={'checkout-failed'}>
                    <Box
                        maxWidth={'500px'}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        padding={'20px'}
                    >
                        <div className={'checkout-failed__close-icon'}>
                            <IconButton onClick={backToStore} size="small">
                                <CloseIcon
                                    style={{ fontSize: 40, color: 'white' }}
                                />
                            </IconButton>
                        </div>
                        <StyledTextContainer
                            headerColor={'white'}
                            headerSize={theme.headerSize}
                            headerWeight={theme.headerWeight}
                            textColor={theme.textColor}
                            textSize={theme.textSize}
                            textWeight={theme.textWeight}
                            className={'checkout-completion__text-container'}
                        >
                            <h1
                                className={
                                    'checkout-completion__text-container__header'
                                }
                                data-testid={'failure-page-header'}
                            >
                                {error
                                    ? dic.headline[error]
                                    : dic.headline.default}
                            </h1>
                            <h2
                                className={
                                    'checkout-completion__text-container__sub-header'
                                }
                                data-testid={'failure-page-desc'}
                            >
                                {error
                                    ? msg
                                        ? [msg]
                                        : [dic.lines[error]]
                                    : [dic.lines.default]}
                            </h2>
                        </StyledTextContainer>
                        <Button
                            variant="text"
                            id="support"
                            fullWidth
                            sx={{
                                color: 'white',
                                fontSize: '12px',
                                border: '1px solid #fff',
                                minWidth: '100%',
                                padding: '13px'
                            }}
                            onClick={openSupport}
                        >
                            {t('contactSupport')}
                        </Button>
                        <div
                            style={{
                                color: 'white',
                                fontSize: '12px',
                                fontWeight: 400
                            }}
                            className={'checkout-failed__payment-reference'}
                            data-testid={'failure-page-desc1'}
                        >
                            <span>{t('paymentReferenceID')}</span>
                            <br />
                            <span>{orderId}</span>
                        </div>
                    </Box>
                </Box>
            ) : (
                <Background
                    backgroundImageDesktop={
                        publisherMetaData.storeTheme.general
                            .backgroundImageDesktop
                    }
                    backgroundImageMobile={
                        publisherMetaData.storeTheme.general
                            .backgroundImageMobile
                    }
                >
                    <MessagePage
                        headerText={
                            error ? dic.headline[error] : dic.headline.default
                        }
                        headerColor={{ colorOne: 'white' }}
                        headerSize={isMobile ? 32 : 44}
                        text={
                            error
                                ? msg
                                    ? [msg]
                                    : [dic.lines[error]]
                                : [dic.lines.default]
                        }
                        textColor={'white'}
                        textSize={16}
                        fontFamily={
                            publisherMetaData.storeTheme.general.font as any
                        }
                        headerWeight={'bold' as EFontWeights}
                        textWeight={'bold' as EFontWeights}
                        dataTestId={{
                            header: 'failure-page-header',
                            line: 'failure-page-desc'
                        }}
                    >
                        <Stack direction="column">
                            <ActionButton
                                border="2px solid white"
                                borderRadius="7px"
                                action={returnToShop}
                                width="340px"
                                text={t('returnToShop')}
                                colors={{ colorOne: 'transparent' }}
                                marginBottom="16px"
                            />
                            {isBackToGameBtnShown && (
                                <BackToGameButton
                                    useNewCheckout={false}
                                    border="2px solid white"
                                    borderRadius="7px"
                                    width="340px"
                                    btnText={
                                        publisherMetaData.storeTheme
                                            .completedScreen
                                            .backToGameButtonText
                                    }
                                    btnColor={{ colorOne: 'transparent' }}
                                    returnToGameLinkAddress={
                                        returnToGameLinkAddress
                                    }
                                />
                            )}
                            {(error === EOrderStatus.CHARGE_FAILED ||
                                error === EOrderStatus.PAYMENT_FAILED) && (
                                <div
                                    data-testid={'failure-page-desc1'}
                                    style={{
                                        color: 'white',
                                        fontSize: '12px',
                                        fontWeight: '100'
                                    }}
                                >
                                    <span>{t('paymentReferenceID')}</span>
                                    <br />
                                    <span>{orderId}</span>
                                </div>
                            )}
                        </Stack>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0',
                                height: '15%'
                            }}
                            width="100%"
                            px={2}
                        >
                            <Button
                                onClick={openSupport}
                                sx={{
                                    color: '#ffffff',
                                    fontFamily: 'Arial',
                                    fontWeight: 'bold',
                                    fontSize: 12
                                }}
                                fullWidth
                            >
                                {t('contactSupport')}
                            </Button>
                        </Box>
                    </MessagePage>
                </Background>
            )}
        </>
    );
};

export default Failed;
