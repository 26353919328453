import { GoogleProfileResponse } from '../constants/apiResponses.types';

export default function useGoogle() {
    const getGoogleProfile = async (userToken: string): Promise<string> => {
        const response = await fetch(
            'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
            {
                headers: new Headers({
                    Authorization: `Bearer ${userToken}`
                })
            }
        );

        const data: GoogleProfileResponse = await response.json();
        return data.picture
            ? data.picture
            : Promise.reject('No picture URL found in the user info');
    };

    return { getGoogleProfile };
}
