import { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import Typography from '@mui/material/Typography';
import InfoModal from '../../components/InfoModal/InfoModal';
import './style.scss';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    EBundlesInternalViewModel,
    EEventsType,
    EStorePhase,
    ESupportType
} from '../../constants/enums';
import useCustomEvents from '../../hooks/useCustomEvents';
import { FooterProps } from './Footer.types';
import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as AppStore } from './images/AppStoreBlack.svg';
import { ReactComponent as GooglePlay } from './images/GooglePlayBlack.svg';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import pmLogo from './images/product-madness-logo.png';
import aristocratLogo from './images/aristocrat-logo.png';

const privacyPolicyLinkProductMadness =
    'https://www.productmadness.com/privacy-notice';
const termsAndConditionsLinkProductMadness =
    'https://www.productmadness.com/terms-of-service';

const Footer = ({ showFooter = true, isScriptLoaded }: FooterProps) => {
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const publisherThemeGeneral = publisherMetaData.storeTheme.general;
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const navigate = useNavigate();
    const customEvents = useCustomEvents();
    const logoImageSrc = isMobile
        ? publisherThemeGeneral.footerImageMobile
        : publisherThemeGeneral.footerImageDesktop;
    const [isProductMadness, setIsProductMadness] = useState(false);

    const isFooterLogoVisible =
        publisherThemeGeneral.isFooterLogoVisible ?? true;

    const { t } = useTranslation();

    useEffect(() => {
        setIsProductMadness(
            [
                EBundlesInternalViewModel.JACKPOT_SLOTS,
                EBundlesInternalViewModel.BIG_FISH_CASINO,
                EBundlesInternalViewModel.CASHMAN_CASINO
            ].includes(
                publisherMetaData.storeTheme.general.bundlesInternalViewModel
            )
        );
    }, [publisherThemeGeneral]);
    const handleSupportClick = () => {
        if (!isScriptLoaded) {
            return;
        }
        customEvents.sendCustomEvent(
            EEventsType.SUPPORT_FORM_OPEN,
            {
                phase: EStorePhase.POST_LOGIN
            },
            EStorePhase.POST_LOGIN
        );
        if (
            publisherMetaData.supportConfiguration?.externalSupportUrl &&
            !publisherMetaData.supportConfiguration.postLoginSnippet
        ) {
            window.open(
                publisherMetaData.supportConfiguration.externalSupportUrl,
                '_blank'
            );
            return;
        }
        if (publisherMetaData.supportConfiguration?.postLoginSnippet) {
            API.sendEmptySupport(
                publisherMetaData.storeTheme.publisherId,
                ESupportType.POST_LOGIN,
                EStorePhase.POST_LOGIN
            );
        } else {
            navigate(
                `/support/${ESupportType.POST_LOGIN}/${EStorePhase.POST_LOGIN}`
            );
        }
    };

    const openModal = (url: string) => {
        if (isProductMadness) {
            window.open(url, '_blank');
        } else {
            setIframeUrl(url);
            setShowInfoModal(true);
        }
    };

    const closeModal = () => {
        setShowInfoModal(false);
    };

    return (
        <div className={'shop-footer ' + (showFooter ? '' : 'hide')}>
            <div className="footerContainer">
                {isProductMadness && (
                    <Stack maxWidth="400px" minWidth="320px" mb={5}>
                        <Box mb={2}>
                            <Typography
                                color="white"
                                sx={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '11px',
                                    textAlign: 'center',
                                    lineHeight: '150%'
                                }}
                            >
                                {t('footer.byClicking')}{' '}
                                <a
                                    href={privacyPolicyLinkProductMadness}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('footer.privacyPolicy')}
                                </a>{' '}
                                {t('common.and')}{' '}
                                <a
                                    href={termsAndConditionsLinkProductMadness}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('footer.termsAndConditionsLong')}
                                </a>
                            </Typography>
                        </Box>
                        <Typography
                            color="white"
                            sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '11px',
                                textAlign: 'center',
                                lineHeight: '150%'
                            }}
                        >
                            {t('footer.legalOver18')}
                        </Typography>
                        <div className={'pm-footer-logos'}>
                            <img src={pmLogo} alt="pm-logo" />
                            <img src={aristocratLogo} alt="aristocrat-logo" />
                        </div>
                    </Stack>
                )}
                <Stack
                    direction="row-reverse"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    position={'relative'}
                    width={'100%'}
                >
                    {logoImageSrc && (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img
                                className="publisherLogo"
                                src={logoImageSrc}
                                alt="logo"
                            />
                        </Stack>
                    )}
                    {publisherThemeGeneral.footerAppstoreLink &&
                        publisherThemeGeneral.footerGooglePlayLink && (
                            <Stack
                                direction={isMobile ? 'column' : 'row'}
                                justifyContent="center"
                                alignItems="center"
                                gap={'4px'}
                            >
                                <a
                                    href={
                                        publisherThemeGeneral.footerAppstoreLink
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <AppStore />
                                </a>
                                <a
                                    href={
                                        publisherThemeGeneral.footerGooglePlayLink
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <GooglePlay />
                                </a>
                            </Stack>
                        )}
                    <span className="footer-divider"></span>
                </Stack>
                <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    alignItems={isMobile ? 'center' : 'flex-start'}
                    mb={'18px'}
                    mt={'18px'}
                    gap={isMobile ? 2 : 0}
                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {isFooterLogoVisible && <Logo />}
                    </Stack>
                    <Stack direction="column" gap={1}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            marginRight={2}
                            width={'100%'}
                            gap={1}
                        >
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                    fontSize={14}
                                    onClick={() =>
                                        openModal(
                                            publisherMetaData.publisher
                                                .privacyPolicyUrl
                                        )
                                    }
                                >
                                    {t('footer.privacy')}
                                </Typography>
                            </div>
                            <span className="quadraticDot"></span>
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    textAlign="center"
                                    fontSize={14}
                                    onClick={() =>
                                        openModal(
                                            publisherMetaData.publisher
                                                .termsAndConditionsUrl
                                        )
                                    }
                                >
                                    {t('footer.termsAndConditions')}
                                </Typography>
                            </div>
                            <span className="quadraticDot"></span>
                            <div>
                                <Typography
                                    id="support"
                                    variant="subtitle1"
                                    textAlign="center"
                                    fontSize={14}
                                    style={{
                                        pointerEvents: isScriptLoaded
                                            ? 'auto'
                                            : 'none',
                                        opacity: isScriptLoaded ? 1 : 0.5
                                    }}
                                    onClick={handleSupportClick}
                                >
                                    {t('footer.support')}
                                </Typography>
                            </div>
                            {publisherMetaData?.featureFlags
                                .store_multi_language_support && (
                                <>
                                    <span className="quadraticDot"></span>
                                    <LanguagePicker fontSize={14} />
                                </>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            {showInfoModal && iframeUrl && (
                <InfoModal closeModal={closeModal} iframeUrl={iframeUrl} />
            )}
        </div>
    );
};

export default Footer;
