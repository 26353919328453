export const paymentMethodsDefault = [
    {
        paymentMethodName: 'card',
        paymentMethodDisplayName: 'Credit Card',
        paymentMethodAssetUrl:
            'https://media.appcharge.com/defaults/credit-card.svg'
    }
];

export const APPCHARGE_PRIVACY_POLICY_URL =
    'https://appcharge.com/privacy-policy/';
