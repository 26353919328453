import { isAndroid, isIOS } from 'react-device-detect';
import { DeepLink } from '@appcharge/shared-ui/lib/components/Helper/types';
import { EPlatformType } from '../../constants/enums';

export const getReturnToGameLinkAddress = (deepLinks: DeepLink[]) => {
    return deepLinks.find((link) => {
        if (isIOS && link.platform === EPlatformType.IOS) {
            return true;
        } else if (isAndroid && link.platform === EPlatformType.ANDROID) {
            return true;
        } else if (
            !isIOS &&
            !isAndroid &&
            link.platform === EPlatformType.WEB
        ) {
            return true;
        }
        return false;
    })?.deepLink;
};
