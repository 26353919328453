import { useEffect, useState } from 'react';
import { BootResponse } from '../constants/apiResponses.types';
import { isAndroid } from 'react-device-detect';

declare global {
    interface Window {
        deferredPrompt: InstallPromptEvent | null;
    }
}

interface InstallPromptEvent extends Event {
    prompt: () => Promise<void>;
}

if (typeof window !== 'undefined' && isAndroid) {
    window.deferredPrompt = null;

    window.addEventListener('beforeinstallprompt', (e: any) => {
        console.log(
            'Global listener: beforeinstallprompt event triggered on Android'
        );
        window.deferredPrompt = e;
        e.preventDefault();
    });
}

const useSaveToHomePage = (publisherMetaData: BootResponse) => {
    const [useSaveToHomePageNotification, setUseSaveToHomePageNotification] =
        useState(false);

    const [isSaveToHomePageOpen, setIsSaveToHomePageOpen] = useState(false);
    const [isDisplayAddToHomePageButton, setIsDisplayAddToHomePageButton] =
        useState(true);

    const [installPrompt, setInstallPrompt] =
        useState<InstallPromptEvent | null>(null);

    const [isInstallPromptAvailable, setIsInstallPromptAvailable] =
        useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            e.preventDefault();
            console.log('beforeinstallprompt event triggered');
            window.deferredPrompt = e;
            setInstallPrompt(e);
            setIsInstallPromptAvailable(true);
        };

        window.addEventListener(
            'beforeinstallprompt',
            handleBeforeInstallPrompt
        );

        if (window.deferredPrompt) {
            console.log('Found deferredPrompt on window');
            setInstallPrompt(window.deferredPrompt);
            setIsInstallPromptAvailable(true);
        }

        return () => {
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            );
        };
    }, []);

    useEffect(() => {
        const handleAppInstalled = () => {
            setIsDisplayAddToHomePageButton(false);
        };

        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const handleInstallApp = async () => {
        if (installPrompt) {
            try {
                await installPrompt.prompt();
                console.log('User accepted the A2HS prompt');
                setInstallPrompt(null);
                window.deferredPrompt = null;
            } catch (error) {
                console.error('Install prompt failed', error);
            }
        } else {
            console.log('Install prompt not available');
        }
    };

    useEffect(() => {
        setUseSaveToHomePageNotification(
            !!publisherMetaData.featureFlags
                ?.store_add_to_home_screen_notification
        );
    }, [publisherMetaData]);

    useEffect(() => {
        const isStandalone = () => {
            return window.matchMedia('(display-mode: standalone)').matches;
        };

        if (isStandalone()) {
            setIsDisplayAddToHomePageButton(false);
            setIsSaveToHomePageOpen(false);
        }
    }, []);

    const handleCloseSaveToHomePage = () => setIsSaveToHomePageOpen(false);

    return {
        useSaveToHomePageNotification,
        isSaveToHomePageOpen,
        setIsSaveToHomePageOpen,
        isDisplayAddToHomePageButton,
        handleCloseSaveToHomePage,
        handleInstallApp,
        isInstallPromptAvailable
    };
};

export default useSaveToHomePage;
