
import React, { createContext, ReactNode, useContext } from 'react';

interface NewRelicContextType {
    newRelicBrowserAgent: any | null;
}

const NewRelicContext = createContext<NewRelicContextType>({ newRelicBrowserAgent: null });

export const NewRelicProvider  = ({ children }: {children: ReactNode}) => {
    const newRelicBrowserAgent = (window as any).NREUM || null;
    return(
        <NewRelicContext.Provider value={{ newRelicBrowserAgent }}>
            {children}
        </NewRelicContext.Provider>
)
};

export const useNewRelic = () => useContext(NewRelicContext);

