import { useQuery, useMutation } from 'react-query';
import {
    APIProps,
    BootResponse,
    LoginResponse
} from '../constants/apiResponses.types';
import {
    ELocalStorageKeys,
    ESupportType,
    EQueryKeys,
    EEventsType,
    EStorePhase,
    EDeviceType
} from '../constants/enums';
import useAxios from './useAxios';
import { localStorageUtil } from '../utils';
import { SupportData } from '../constants/support.types';
import useCustomEvents from './useCustomEvents';
import { isDesktop } from 'react-device-detect';
import { LoginEventData } from '../constants/apiRequest.types';
import useFEMonitoring from './useFEMonitoring';

export default function useApi({ orderId, platform }: APIProps) {
    const axios = useAxios();
    const customEvents = useCustomEvents();
    const {logToNewRelic} = useFEMonitoring();

    const getOffers = useQuery({
        queryKey: EQueryKeys.OFFERS,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        queryFn: async () => {
            return localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE)
                ? JSON.parse(
                      localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE)
                  )
                : await axios.get(`/store/offers`).catch((err) => {
                      customEvents.sendCustomEvent(
                          EEventsType.ERROR,
                          {
                              type: 'api error',
                              route: 'getOffers'
                          },
                          EStorePhase.POST_LOGIN
                      );
                      logToNewRelic(err);
                      throw err;
                  });
        }
    });

    const getPaypalKey = useQuery({
        queryKey: EQueryKeys.PAYPAL,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        queryFn: async () => {
            return await axios
                .get('/store/paypal/client-token')
                .catch((err) => {
                    customEvents.sendCustomEvent(
                        EEventsType.ERROR,
                        {
                            type: 'api error',
                            route: 'getPaypalKey'
                        },
                        EStorePhase.POST_LOGIN
                    );
                    logToNewRelic(err);
                    throw err;
                });
        }
    });

    const getPublisherMeta = useQuery<BootResponse, Error>({
        queryKey: EQueryKeys.BOOT,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        queryFn: async () => {
            const response = await axios
                .get(
                    `store/${
                        localStorageUtil.get(
                            ELocalStorageKeys.OVERRIDE_STORE
                        ) || window.location.host
                    }/boot`,
                    true
                )
                .catch((err) => {
                    customEvents.sendCustomEvent(
                        EEventsType.ERROR,
                        {
                            type: 'api error',
                            route: 'getPublisherMeta'
                        },
                        EStorePhase.PRE_LOGIN
                    );
                    logToNewRelic(err);
                    throw err;
                });
            localStorageUtil.set(ELocalStorageKeys.PUBLISHER_META, response);
            return localStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE)
                ? JSON.parse(
                      localStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE)
                  )
                : response;
        }
    });

    const getOTP = () => {
        if (localStorageUtil.get(ELocalStorageKeys.OTP_API_OVERRIDE)) {
            return new Promise((resolve) => {
                resolve({
                    data: JSON.parse(
                        localStorageUtil.get(ELocalStorageKeys.OTP_API_OVERRIDE)
                    )
                });
            });
        }
        return axios
            .post(
                `/store/otp/deeplink`,
                {
                    data: {
                        device: isDesktop ? EDeviceType.DESKTOP : 'APPCHARGE',
                        publisherId: localStorageUtil.get(
                            ELocalStorageKeys.PUBLISHER_META
                        ).storeTheme.publisherId,
                        platform: platform?.toUpperCase()
                    },
                    headers: {}
                },
                true
            )
            .catch((err) => {
                return logToNewRelic(err).then(() => {
                    return customEvents.sendCustomEvent(
                        EEventsType.ERROR,
                        {
                            type: 'api error',
                            route: 'getOTP'
                        },
                        EStorePhase.PRE_LOGIN
                    )
                }).finally(() => {
                    throw err;
                });
            });
    };

    const getPostLoginData = useQuery({
        queryKey: EQueryKeys.POST_LOGIN,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        queryFn: async () => {
            return localStorageUtil.get(
                ELocalStorageKeys.POST_LOGIN_API_OVERRIDE
            )
                ? JSON.parse(
                      localStorageUtil.get(
                          ELocalStorageKeys.POST_LOGIN_API_OVERRIDE
                      )
                  )
                : await axios.get('/store/postLogin').catch((err) => {
                      customEvents.sendCustomEvent(
                          EEventsType.ERROR,
                          {
                              type: 'api error',
                              route: 'getPostLoginData'
                          },
                          EStorePhase.POST_LOGIN
                      );
                      logToNewRelic(err);
                      throw err;
                  });
        }
    });

    const getOrder = useQuery({
        queryKey: `${EQueryKeys.ORDER}_${orderId}`,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        queryFn: async () => {
            return await axios.get(`store/orders/${orderId}`).catch((err) => {
                customEvents.sendCustomEvent(
                    EEventsType.ERROR,
                    {
                        type: 'api error',
                        route: 'getOrder'
                    },
                    EStorePhase.POST_LOGIN
                );
                logToNewRelic(err);
                throw err;
            });
        }
    });

    const createCheckoutSession = useMutation(async (data: any) => {
        return await axios.post(`/store/orders/checkout-session`, data);
    }, {
        onError: async (err: any) => {
            await logToNewRelic(err.response?.data);
        }
    });

    const createOrder = useMutation(async (data: any): Promise<any> => {
        return await axios.post(`/store/orders`, data);
    }, {
        onError: async (err: any) => {
            await logToNewRelic(err.response?.data);
        }
    });

    const checkoutOrder = useMutation(async (data: any) => {
        return await axios.post(`/store/orders/checkout`, data);
    }, {
        onError: async (err: any) => {
            await logToNewRelic(err.response?.data);
        }
    });

    const login = useMutation(async (data: any) => {
        return await axios.post<LoginResponse | any>(
            '/store/auth/login',
            data,
            true
        );
    }, {
        onError: async (err: any) => {
            await logToNewRelic(err.response?.data);
        }
    });

    const logout = useMutation(async (data?: any) => {
        return await axios.post('/store/auth/logout', data);
    }, {
        onError: async (err: any) => {
            await logToNewRelic(err.response?.data);
        }
    });

    const keepAlive = useMutation({
        retry: false,
        mutationFn: async (data: any) => {
            return await axios.post('/store/auth/keepalive', data);
        }
    });

    const sendSupport = useMutation(
        async ({
            data,
            supportType
        }: {
            data: SupportData;
            supportType: ESupportType;
        }) => {
            const url =
                supportType === ESupportType.POST_LOGIN
                    ? 'store/support/postlogin'
                    : 'store/support/prelogin';
            return await axios.post(url, {
                data: data,
                headers: {}
            });
        },
        {
            onError: async (err: any) => {
                await logToNewRelic(err.response?.data);
            }
        }
    );

    const sendEmptySupport = (
        publisherId: string,
        supportPhase: ESupportType,
        storePhase: EStorePhase,
        orderId?: string
    ) => {
        sendSupport.mutateAsync(
            {
                data: {
                    publisherId: publisherId,
                    supportFullName: '',
                    communicationType: '',
                    communicationDetails: '',
                    supportRequestReason: '',
                    playerStorePhase: storePhase,
                    playerMessage: '',
                    orderId: orderId || ''
                },
                supportType: supportPhase
            },
            {
                onError: async (err) => {
                    console.error(err);
                    customEvents.sendCustomEvent(
                        EEventsType.ERROR,
                        {
                            type: 'api error',
                            route: 'sendSupport'
                        },
                        storePhase
                    );
                    await logToNewRelic(err.response?.data);
                }
            }
        );
    };

    const handleLoginEvent = useMutation(async (data: LoginEventData) => {
        return await axios
            .post(`/store/events/login`,
                { data, headers: {}
            })
            .catch((err) => {
                return logToNewRelic(err).then(() => {
                    console.error(err);
                });
            });
    }, {});

    const sendOrderImpression = (data: any) => {
        const url = 'store/event';
        return axios
            .post(url, {
                data: {
                    eventName: 'impression',
                    ...data
                },
                headers: {}
            })
            .catch((err) => {
                return logToNewRelic(err).then(() => {
                    console.error(err);
                });
            });
    };

    return {
        login,
        logout,
        getOrder,
        getOffers,
        keepAlive,
        sendSupport,
        createOrder,
        checkoutOrder,
        getPaypalKey,
        createCheckoutSession,
        getOTP,
        getPublisherMeta,
        getPostLoginData,
        sendEmptySupport,
        handleLoginEvent,
        sendOrderImpression
    };
}
