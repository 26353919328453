export default function useScript() {
    const injectScript = (src: string, callback?: () => void) => {
        if (!src) return;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function () {
            if (!callback) return;
            callback();
        };
        script.src = src;
        document.getElementsByTagName('body')[0].appendChild(script);
    };

    return { injectScript };
}
