import Slider from 'react-slick';
import { CarouselProps } from './Carousel.types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';
import { ESpecialOfferInternalViewModel } from '../../constants/enums';

const Carousel = ({
    children,
    showDivider,
    bundleViewModel
}: CarouselProps) => {
    const slidesToShow =
        bundleViewModel === ESpecialOfferInternalViewModel.LOCO_BINGO
            ? children.length < 2
                ? children.length
                : 2
            : children.length < 3
            ? children.length
            : 3;

    const settings = {
        dots: bundleViewModel !== ESpecialOfferInternalViewModel.ME2ON,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint:
                    bundleViewModel ===
                    ESpecialOfferInternalViewModel.LOCO_BINGO
                        ? 767
                        : 848,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1263,
                settings: {
                    slidesToShow: children.length === 1 ? 1 : 2
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings} className={bundleViewModel}>
                {children}
            </Slider>
            {!showDivider && (
                <hr
                    className={`carousel-divider carousel-divider-${bundleViewModel}`}
                />
            )}
        </>
    );
};

export default Carousel;
