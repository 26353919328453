import { useEffect } from 'react';
import { BootResponse } from '../constants/apiResponses.types';
import useApi from './useApi';
import { useSetCssVars as setCss } from '@appcharge/shared-ui';

export default function useSetCssVars() {
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;

    useEffect(() => {
        if (!publisherMetaData || !publisherMetaData.storeTheme) {
            return;
        }
        setCss(publisherMetaData.storeTheme as any, document);
    }, [publisherMetaData]);
}
