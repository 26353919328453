import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import { EStorePhase, EEventsType } from '../../constants/enums';
import { LogoutModalProps } from './LogoutModal.types';
import { ActionButton, Modal } from '@appcharge/shared-ui';
import useCustomEvents from '../../hooks/useCustomEvents';
import { useTranslation } from 'react-i18next';

const LogoutModal = ({ closePopup, logout, playerName }: LogoutModalProps) => {
    const API = useApi({});
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const customEvents = useCustomEvents();

    const { t } = useTranslation();

    const logoutHandler = () => {
        closePopup();
        customEvents.sendCustomEvent(
            EEventsType.LOGOUT,
            {
                reason: 'logout clicked'
            },
            EStorePhase.POST_LOGIN
        );
        logout();
    };

    return (
        <Modal
            closeModal={closePopup}
            title={t('logoutModal.logout')}
            titleColor={
                publisherMetaData.storeTheme.paymentScreen.headerColor ||
                'black'
            }
            titleSize={publisherMetaData.storeTheme.paymentScreen.headerSize}
            subtitle={`${playerName ? `${playerName}, ` : ''}${t(
                'logoutModal.disconnectConfirmation'
            )}`}
            subtitleColor={publisherMetaData.storeTheme.paymentScreen.textColor}
            subtitleSize={publisherMetaData.storeTheme.paymentScreen.textSize}
            fontFamily={publisherMetaData.storeTheme.general.font}
            modalColor={publisherMetaData.storeTheme.paymentScreen.popupColor}
            modalBorderColor={
                publisherMetaData.storeTheme.paymentScreen.popupBorderColor
            }
        >
            <ActionButton
                text={t('logoutModal.logout')}
                action={logoutHandler}
                colors={publisherMetaData.storeTheme.general.buttonColor}
            />
        </Modal>
    );
};

export default LogoutModal;
