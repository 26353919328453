import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from './locales/translations';

// TODO add supported languages ('de','es') to the array, when common.json will be ready
export const supportedLanguages = ['en', 'ja', 'es'];

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        resources,
        interpolation: {
            escapeValue: false
        },
        preload: supportedLanguages,
        ns: ['common'],
        defaultNS: ['common'],
        nsSeparator: '.',
        returnNull: false,
        detection: {
            order: [
                'localStorage',
                'querystring',
                'cookie',
                'navigator',
                'htmlTag',
                'path',
                'subdomain'
            ],
            caches: ['localStorage'], // TODO: Remove localStorage and 'ac_language' to allow the browser to automatically determine the language.
            lookupLocalStorage: 'ac_language'
            // lookupQuerystring: 'lng',
            // lookupCookie: 'i18next',
            // lookupLocalStorage: 'i18nextLng'
        }
    });
export default i18n;
